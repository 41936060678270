import React, { useState } from "react";
import {
  AppstoreOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ArrowDownOutlined 
} from "@ant-design/icons";
import { Button, Layout, Menu, theme, Grid, Drawer } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const { Header, Content, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;

const page = ["dashboard", "profile", "faq" , 'freepick-downloader' , "envato-downloader"];

const items = [AppstoreOutlined, UserOutlined, QuestionCircleOutlined , ArrowDownOutlined , ArrowDownOutlined].map(
  (icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: (
      <Link to={`/${page[index]}`}>
        {page[index].charAt(0).toUpperCase() + page[index].slice(1)}
      </Link>
    ),
  })
);

const Main = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  const currentKey = String(
    page.findIndex((p) => location.pathname.includes(p)) + 1
  );

  const logOut = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout>
      {/* Use Drawer for mobile devices */}
      {screens.md ? (
        <Sider
          
         
          style={{
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
          }}
          width={200}
          className="pt-14 z-50"
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[currentKey]}
            defaultSelectedKeys={["1"]}
            items={items}
          />
          <div className="flex w-full justify-center">
            <Link
              to={`/request-graphics`}
              className="text-white bg-[#1677ff] py-2 text-base mt-10 rounded-md px-6"
              type="primary"
            >
              Request Graphics
            </Link>
          </div>
          <div className="flex w-full justify-center my-10">
            <Button
              type="default"
              style={{
                borderColor: "red",
                color: "red",
                backgroundColor: "transparent",
                borderWidth: "2px",
              }}
              onClick={logOut}
            >
              Logout
            </Button>
          </div>
        </Sider>
      ) : (
        <Drawer
          title="Menu"
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          open={drawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[currentKey]}
            defaultSelectedKeys={["1"]}
            items={items}
          />
          <div className="flex w-full justify-center">
            <Link
              to={`/request-graphics`}
              className="text-white bg-[#1677ff] py-2 text-base mt-10 rounded-md px-6"
              type="primary"
            >
              Request Graphics
            </Link>
          </div>
          <div className="flex w-full justify-center my-10">
            <Button
              type="default"
              style={{
                borderColor: "red",
                color: "red",
                backgroundColor: "transparent",
                borderWidth: "2px",
              }}
              onClick={logOut}
            >
              Logout
            </Button>
          </div>
        </Drawer>
      )}

      <Layout
        style={{
          marginLeft: screens.md ? 200 : 0, // Add margin only on larger screens
        }}
      >
        <Header
          className="sticky top-0 z-50 flex items-center justify-between bg-[#001529] px-4"
        >
          {!screens.md && (
            <Button
              type="text"
              icon={drawerVisible ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggleDrawer}
              style={{ color: "white" }}
            />
          )}
          <p className="text-[#FFEB00] text-sm md:text-xl">{user?.user?.daysLeft} Days Left</p>
          <p className="text-white text-sm md:text-xl font-semibold">
            Active Premium Services
          </p>
          <p className="text-white text-sm md:text-lg">Hey, {user?.user?.username}</p>
        </Header>

        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
          className="min-h-[35.5rem]"
        >
          <div
            style={{
              padding: 24,
              textAlign: "center",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
            className="h-full relative z-10"
          >
            {children}
          </div>
        </Content>

        <Footer style={{ textAlign: "center" }} className="mt-auto">
          The Designer Bay ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Main;
